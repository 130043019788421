@import url(https://fonts.googleapis.com/css2?family=Almarai:wght@700&display=swap);
@-webkit-keyframes comeFromTop {
  0% {
    top: -100px;
  }
  100% {
    top: -10px;
  }
}
@keyframes comeFromTop {
  0% {
    top: -100px;
  }
  100% {
    top: -10px;
  }
}
@-webkit-keyframes comeFromRight {
  0% {
    margin-right: 500px;
    opacity: 0;
  }
  100% {
    margin-right: 0px;
    opacity: 1;
  }
}
@keyframes comeFromRight {
  0% {
    margin-right: 500px;
    opacity: 0;
  }
  100% {
    margin-right: 0px;
    opacity: 1;
  }
}
@-webkit-keyframes appears {
  0% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
@keyframes appears {
  0% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes scaleUP {
  0% {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@keyframes scaleUP {
  0% {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@-webkit-keyframes a-ltr-after {
  0% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
  100% {
    -webkit-transform: translateX(101%);
            transform: translateX(101%);
  }
}
@keyframes a-ltr-after {
  0% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
  100% {
    -webkit-transform: translateX(101%);
            transform: translateX(101%);
  }
}

@-webkit-keyframes a-ltr-before {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  100% {
    -webkit-transform: translateX(200%);
            transform: translateX(200%);
  }
}

@keyframes a-ltr-before {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  100% {
    -webkit-transform: translateX(200%);
            transform: translateX(200%);
  }
}

@font-face {
  font-family: "Anton-Regular";
  src: url(../../static/media/Anton-Regular.db746a18.ttf) format("truetype");
}
@font-face {
  font-family: "Almarai-Regular";
  src: url(../../static/media/Almarai-Regular.4a38a9e6.ttf) format("truetype");
}
@font-face {
  font-family: "Almarai-Light";
  src: url(../../static/media/Almarai-Light.df410652.ttf) format("truetype");
}
@font-face {
  font-family: "Almarai-ExtraBold";
  src: url(../../static/media/Almarai-ExtraBold.fd9b394b.ttf) format("truetype");
}
@font-face {
  font-family: "OpenSans-Regular";
  src: url(../../static/media/OpenSans-Regular.1b0809d5.ttf) format("truetype");
}
@font-face {
  font-family: "OpenSans-Light";
  src: url(../../static/media/OpenSans-Light.ed41bedf.ttf) format("truetype");
}
@font-face {
  font-family: "Oswald-Regular";
  src: url(../../static/media/Oswald-Regular.19286529.ttf) format("truetype");
}
@font-face {
  font-family: "Oswald-Light";
  src: url(../../static/media/Oswald-Light.5b37a8f3.ttf) format("truetype");
}
@font-face {
  font-family: "Roboto-Regular";
  src: url(../../static/media/Roboto-Regular.a8d6ac03.ttf) format("truetype");
}
@font-face {
  font-family: "Lateef-Regular";
  src: url(../../static/media/Lateef-Regular.847f5284.ttf) format("truetype");
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "OpenSans-Regular";
}

html {
  font-size: 62.5%; /* 62.5 init all browser to 10px*/
  scroll-behavior: initial;
}

body {
  font-family: "Almarai-Regular", "Roboto-Regular", "Segoe UI", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
p,
span {
  color: rgb(41, 41, 41);
}

a {
  text-decoration: none !important;
}

li {
  list-style: none;
}

.app-widget-btn {
  background-color: #a01c1c !important;
  transition: background-color 0.5s ease !important;
  z-index: 10 !important;
  right: 0px;
  color: white !important;
}
@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) {
    .app-widget-btn {
      right: 15px;
    }
  }
}

.app-widget-btn:hover {
  background-color: #cf222e !important;
}
@media screen and (max-width: 760px) {
  .app-widget-btn {
    right: 0px;
  }
}

.slide h1 {
  transition: all 0.3s ease;
  -webkit-transform: translateY(-20px);
  transform: translateY(-20px);
  opacity: 0;
}

.slide button {
  transition: all 0.3s ease;
  -webkit-transform: translateY(20px);
  transform: translateY(20px);
  opacity: 0;
}

.slide p {
  transition: all 0.3s ease;
  -webkit-transform: translateY(20px);
  transform: translateY(20px);
  opacity: 0;
}

.slide section * {
  transition: all 0.3s ease;
}

.slide section img {
  -webkit-transform: translateX(-10px);
  transform: translateX(-10px);
  opacity: 0;
}

.slide section span {
  -webkit-transform: translateY(-10px);
  transform: translateY(-10px);
  opacity: 0;
}

.slide section span strong {
  -webkit-transform: translateY(10px);
  transform: translateY(10px);
  opacity: 0;
}

.slide.animateIn.previous h1,
.slide.current h1,
.slide.animateIn.next h1,
.slide.animateIn.previous button,
.slide.current button,
.slide.animateIn.next button,
.slide.animateIn.previous p,
.slide.current p,
.slide.animateIn.next p,
.slide.animateIn.previous section *,
.slide.current section *,
.slide.animateIn.next section * {
  -webkit-transform: translateX(0);
  transform: translateX(0);
  transition-delay: 0.9s;
  opacity: 1;
}

.slide.animateIn.previous p,
.slide.animateIn.next p {
  transition-delay: 1.1s;
}

.slide.animateIn.previous button,
.slide.animateIn.next button {
  transition-delay: 1.3s;
}

.slide.animateIn.previous section img,
.slide.animateIn.next section img {
  transition-delay: 1.3s;
}

.slide.animateIn.previous section span,
.slide.animateIn.next section span {
  transition-delay: 1.4s;
}

.slide.animateIn.previous section span strong,
.slide.animateIn.next section span strong {
  transition-delay: 1.5s;
}

.slide.animateOut h1 {
  transition-delay: 0.3s;
}

.slide.animateOut p {
  transition-delay: 0.2s;
}

.slide.animateOut section span {
  transition-delay: 0.1s;
}

.slide.animateOut section span strong {
  transition-delay: 0s;
}

