@import url("https://fonts.googleapis.com/css2?family=Almarai:wght@700&display=swap");
@import "../node_modules/video-react/dist/video-react.css";
@import "./assets//style//animation.css";

@font-face {
  font-family: "Anton-Regular";
  src: url("assets/fonts/Anton-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Almarai-Regular";
  src: url("assets/fonts/Almarai-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Almarai-Light";
  src: url("assets/fonts/Almarai-Light.ttf") format("truetype");
}
@font-face {
  font-family: "Almarai-ExtraBold";
  src: url("assets/fonts/Almarai-ExtraBold.ttf") format("truetype");
}
@font-face {
  font-family: "OpenSans-Regular";
  src: url("assets/fonts/OpenSans-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "OpenSans-Light";
  src: url("assets/fonts/OpenSans-Light.ttf") format("truetype");
}
@font-face {
  font-family: "Oswald-Regular";
  src: url("assets/fonts/Oswald-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Oswald-Light";
  src: url("assets/fonts/Oswald-Light.ttf") format("truetype");
}
@font-face {
  font-family: "Roboto-Regular";
  src: url("assets/fonts/Roboto-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Lateef-Regular";
  src: url("assets/fonts/Lateef-Regular.ttf") format("truetype");
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "OpenSans-Regular";
}

html {
  font-size: 62.5%; /* 62.5 init all browser to 10px*/
  scroll-behavior: initial;
}

body {
  font-family: "Almarai-Regular", "Roboto-Regular", "Segoe UI", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
p,
span {
  color: rgb(41, 41, 41);
}

a {
  text-decoration: none !important;
}

li {
  list-style: none;
}

.app-widget-btn {
  background-color: #a01c1c !important;
  transition: background-color 0.5s ease !important;
  z-index: 10 !important;
  right: 0px;
  color: white !important;
}
@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) {
    .app-widget-btn {
      right: 15px;
    }
  }
}

.app-widget-btn:hover {
  background-color: #cf222e !important;
}
@media screen and (max-width: 760px) {
  .app-widget-btn {
    right: 0px;
  }
}
