@keyframes comeFromTop {
  0% {
    top: -100px;
  }
  100% {
    top: -10px;
  }
}
@keyframes comeFromRight {
  0% {
    margin-right: 500px;
    opacity: 0;
  }
  100% {
    margin-right: 0px;
    opacity: 1;
  }
}
@keyframes appears {
  0% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
@keyframes scaleUP {
  0% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes a-ltr-after {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(101%);
  }
}

@keyframes a-ltr-before {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(200%);
  }
}
